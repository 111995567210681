import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { SharedModule } from './shared/shared.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { HeaderInterceptor } from './shared/helper/Interceptor/HeaderInterceptor';
import { CommonService } from './shared/helper/service/common.service';
import { AuthService } from './shared/helper/service/auth.service';
import { EncrDecrService } from './shared/helper/service/encr-decr.service';
import { GetSetService } from './shared/helper/service/get-set.service';
import { AuthGuard } from './shared/helper/Guard/auth.guard';
import { BehavioursubjectService } from './shared/helper/service/behavioursubject.service';
import { HttpClientExtended } from './shared/helper/service/HttpClientExtended';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartService } from './services/cart.service';
import { VerifyOtpEnquiryComponent } from './pages/verify-otp-enquiry/verify-otp-enquiry.component';
import { SitemapComponent } from './pages/staticpages/sitemap/sitemap.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { FAQSComponent } from './pages/staticpages/faqs/faqs.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAir4tXhx3X-wcdZnhe8TLlo9J2m_AKx6w',
    }),
    SharedModule,
    routing,
  ],
  declarations: [
    AppComponent,
    NotFoundComponent,
    MenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    VerifyOtpEnquiryComponent,
    SitemapComponent,
    SidenavMenuComponent,
  ],
  providers: [
    AppSettings,
    AppService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay],
    },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },

    CommonService,
    AuthService,
    EncrDecrService,
    GetSetService,
    AuthGuard,
    BehavioursubjectService,
    CartService,
    HttpClientExtended,
  ],
  bootstrap: [AppComponent],
  entryComponents: [VerifyOtpEnquiryComponent],
})
export class AppModule {}
