import { Injectable } from '@angular/core';

import { Observable,  } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { Route, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ResponseModel, HttpStatusCode } from '../Responsemodel/responsemodel';
import { InterceptorSkipHeader } from '../Interceptor/HeaderInterceptor';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  token: string | string[];
  isDraft = false;
  isPostedId = '0';

  roleData = {
    RoleId: 0,
    LookingForId: 0,
  };


  constructor(
    private http: HttpClient,
    private titleService: Title,
  ) {}

  get(url: string): Observable<any> {
    return this.http.get(url).pipe(
      map((response: ResponseModel<any>) => {
        if (
          response.status === HttpStatusCode.OK ||
          HttpStatusCode.NO_CONTENT
        ) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
        }
      }),
      shareReplay(1)
    );
  }

  post(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);
    return this.http.post(url, body).pipe(
      map((response: ResponseModel<any>) => {
        if (
          response.status === HttpStatusCode.OK ||
          HttpStatusCode.UNAUTHORIZED
        ) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
          // this.route.navigate(['/not-found']);
        }
      })
    );
  }
  public async toBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  // For Post upload
  upload(url: string, formData: any): Observable<any> {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    return this.http.post(url, formData, {
      headers,
    });
  }

  // For set title to all Pages
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  getUserProfileInfo(url: string): Observable<any> {
    return this.http.get(url).pipe(
      map((response: ResponseModel<any>) => {
        if (
          response.status === HttpStatusCode.OK ||
          HttpStatusCode.NO_CONTENT
        ) {
          return response;
        } else {
          return response;
        }
      })
    );
  }
}
