import { Component, OnInit } from '@angular/core';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  breadcrumb = [
    {
      name: 'Contact Us',
      url: '/contactus',
    },
  ];
  constructor(private behaviourSubjectService:BehavioursubjectService) { }

  ngOnInit(): void {
    this.behaviourSubjectService.changeBreadCrumbData(this.breadcrumb);

  }

}
