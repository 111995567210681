import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CityService } from 'src/app/services/city.service';
import { OpportunityService } from 'src/app/services/Opportunity.service';
import { ResponseModel } from 'src/app/shared/helper/Responsemodel/responsemodel';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';
import { GetSetService } from 'src/app/shared/helper/service/get-set.service';
import { AddCityComponent } from '../opportunity/report/add-city/add-city.component';

@Component({
  selector: 'app-verify-otp-enquiry',
  templateUrl: './verify-otp-enquiry.component.html',
  styleUrls: ['./verify-otp-enquiry.component.scss'],
})
export class VerifyOtpEnquiryComponent implements OnInit {
  OTPForm: FormGroup;
  isOTPShow = false;
  isAddCity = false;

  mobileNumber = '';
  otp = '';
  cityName = '';
  constructor(
    private opportunityService: OpportunityService,
    public snackBar: MatSnackBar,
    private getSetService: GetSetService,
    public dialogRef: MatDialogRef<AddCityComponent>,
    private cityService: CityService,
    private formBuilder: FormBuilder,
    private behaviouService:BehavioursubjectService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.OTPForm = this.formBuilder.group({
      input1: ['', [Validators.required, Validators.pattern('[0-9]{1}')]],
      input2: ['', [Validators.required, Validators.pattern('[0-9]{1}')]],
      input3: ['', [Validators.required, Validators.pattern('[0-9]{1}')]],
      input4: ['', [Validators.required, Validators.pattern('[0-9]{1}')]],
    });
  }

  ngOnInit(): void {
    if (
      this.getSetService.isloggedInUser() ||
      this.getSetService.isTempUser()
    ) {
      this.isAddCity = true;
    }
  }

  isNumber(evt) {
    // tslint:disable-next-line: deprecation
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  close() {
    this.dialogRef.close();
  }

  sendOTP() {
    const data = {
      UserName: this.mobileNumber,
      FirstName: '',
      LastName: '',
      Otp: ' ',
    };

    this.opportunityService
      .createTempUser(data)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === 200) {
          if(response.StatusMessage.includes('Welcome to Meticulous')){
            this.snackBar.open(
              'Mobile number is already verified, Now you can submit your Enquiry',
              '×',
              {
                panelClass: 'success',
                // verticalPosition: 'top',
              horizontalPosition:'center',
                duration: 2000,
              }
            );
            this.isAddCity = true;
            this.getSetService.setTempUser(response.data);
            this.behaviouService.getTempUser(true)
            this.close();
          }else{
           
          
          this.isOTPShow = true;
          this.snackBar.open('Otp Sent', '×', {
            panelClass: 'success',
            // verticalPosition: 'top',
            horizontalPosition:'center',
            duration: 2000,
          });
        }
        } else {
          this.snackBar.open(response.StatusMessage, '×', {
            panelClass: 'error',
            // verticalPosition: 'top',
            horizontalPosition:'center',
            duration: 2000,
          });
        }
      });
  }

  verifyOTP() {
    const data1 = {
      UserName: this.mobileNumber,
      FirstName: '',
      LastName: '',
      Otp: this.otp,
    };

    this.opportunityService.verifyTempUser(data1).subscribe((response) => {
      if (response.status === 200) {
        this.snackBar.open(
          'OTP Verified, Now you can submit your Enquiry',
          '×',
          {
            panelClass: 'success',
            // verticalPosition: 'top',
            horizontalPosition:'center',
            duration: 2000,
          }
        );
        this.isAddCity = true;
        this.getSetService.setTempUser(response.data);
        this.behaviouService.getTempUser(true)
        this.close();
      } else {
        this.snackBar.open(response.StatusMessage, '×', {
          panelClass: 'error',
          // verticalPosition: 'top',
          horizontalPosition:'center',
          duration: 2000,
        });
      }
    });
  }

  focusInput(e, id) {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    ) {
      if (+id < 4) {
        if (
          (document.getElementById(`input${+id + 1}`) as HTMLInputElement)
            .value !== null
        ) {
          document.getElementById(`input${+id + 1}`).focus();
        } else {
          document.getElementById(`input${id}`).focus();
        }
      }
    }

    if (e.keyCode === 8) {
      if (+id === 1) {
        document.getElementById(`input${id}`).focus();
      } else {
        document.getElementById(`input${+id - 1}`).focus();
      }
    }

    if (this.getOtp().length === 4) {
      this.verifyOTP();
    }
  }
  getOtp() {
    this.otp = `${this.OTPForm.value.input1}${this.OTPForm.value.input2}${this.OTPForm.value.input3}${this.OTPForm.value.input4}`;
    return this.otp.trim();
  }
}
