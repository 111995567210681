import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetSetService } from '../service/get-set.service';
import { AuthService } from '../service/auth.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    token: string;
    constructor(
        private setgetService: GetSetService,
        private authService: AuthService
    ) {

    }

    req: HttpRequest<any>;

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (sessionStorage.getItem('subtoken') === null) {
            this.token = this.setgetService.getAuthKey();
            // request = request.clone({ headers: request.headers.append('Authkey', this.token) });
        } else {
            this.token = sessionStorage.getItem('subtoken');
        }
        if (request.headers.has(InterceptorSkipHeader)) {
            const headers = request.headers.delete(InterceptorSkipHeader);
            this.req = request.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.token}`

                }
            });
        } else {
            this.token = this.setgetService.getAuthKey();
            this.req = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.token}`
                    // tslint:disable-next-line: max-line-length
                    // Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY3RvcnQiOiIyIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy91c2VyZGF0YSI6IjIiLCJ1bmlxdWVfbmFtZSI6Ik1hZGh1cmkiLCJyb2xlIjoiMCIsImVtYWlsIjoibWFkaHVyaUBnbWFpbC5jb20iLCJnaXZlbl9uYW1lIjoiR3VyYXYiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9tb2JpbGVwaG9uZSI6Ijk5MjI2MzY5NTAiLCJqdGkiOiI0MzVlYjA3My1iYzA4LTQ2M2MtOWEwNi1iYzBiNDJmZjU5MDUiLCJuYmYiOjE1OTg2OTk3MDQsImV4cCI6MTU5OTk5NTcwNCwiaWF0IjoxNTk4Njk5NzA0LCJpc3MiOiJodHRwOi8vb2VjLmNvbSIsImF1ZCI6Imh0dHA6Ly9vZWMuY29tIn0.yq_XqGm6nSZeQx6JgCdxPSIJ2qUCZa94iE8074Da2Cs`

                }
            });
        }

        return next.handle(this.req);
    }
}
