
import { Injectable } from '@angular/core';
import { IIndustry, IndustryModel } from 'src/app/models/industry';
import { IUserInfo } from 'src/app/models/userinfo.model';
import { FeasibilityService } from 'src/app/services/feasibility.service';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
  providedIn: 'root',
})
export class GetSetService {
  constructor(
    private encrDecrService: EncrDecrService,
    private feasibilityService: FeasibilityService
  ) {}

  // Set Curret User

  SetUser(userInfo: any) {
    // localStorage.setItem()
    localStorage.setItem('User', this.encrDecrService.encrypt(userInfo));
    // localStorage.setItem('UserId', userInfo.Id);
  }


  setTempUser(userInfo: any) {
    localStorage.setItem('TempUser', this.encrDecrService.encrypt(userInfo));
  }

  getTempUser() {
    if(localStorage.getItem('TempUser')){
     return this.encrDecrService.decrypt(localStorage.getItem('TempUser')); 
    }

    return null;
    
  }

  getAuthKey(): string {
    this.getCurrentUserInfo();
    const tokan =
      localStorage.getItem('User') !== null
        ? localStorage.getItem('User')
        : localStorage.getItem('TempUser');
    if (tokan === null) {
      return null;
    } else {
      const user = this.encrDecrService.decrypt(tokan);
      if (user !== null) {
        return this.encrDecrService.decrypt(tokan).Token;
      } else {
        return null;
      }
    }
    // return tokan == null ? null : this.encrDecrService.decrypt(tokan).token;
  }

  getIndustirs(): IIndustry[] {
    if (sessionStorage.getItem('industries')) {
      return JSON.parse(sessionStorage.getItem('industries'));
    }

    this.feasibilityService
      .getIndustryList()
      .subscribe((data: IndustryModel) => {
        sessionStorage.setItem('industries', JSON.stringify(data.IndustryList));
        return data.IndustryList;
      });
  }

  // get user information
  getCurrentUserInfo(): IUserInfo {
    let currentUser: any;
    const user = localStorage.getItem('User');

    if (user != null) {
      currentUser = this.encrDecrService.decrypt(user);
    } else {
      if (localStorage.getItem('TempUser')) {
        const tempuser = localStorage.getItem('TempUser');
        currentUser = this.encrDecrService.decrypt(tempuser);
        return currentUser;
      }

      return null;
    }
    return currentUser;
  }

  // Get Login User Id
  GetLoginUserId() {
    let currentUser: any;
    const user = localStorage.getItem('User');
    if (user != null) {
      currentUser = this.encrDecrService.decrypt(user);
    } else {
      return 0;
    }
    return currentUser.Id;
  }

  // Get User Step Id
  GetStepId() {
    const user = this.getCurrentUserInfo();
    if (user !== null) {
      // return user.stepId;
    } else {
      return null;
    }
  }

  // get user Substep Id
  GetSubStepId() {
    const user = this.getCurrentUserInfo();
    if (user !== null) {
      // return user.subStepId;
    } else {
      return null;
    }
  }

  // Get User Role/Profile Id
  GetProfileId() {
    const user = this.getCurrentUserInfo();

    if (user !== null) {
      // return user.profileId;
    } else {
      return null;
    }
  }

  GetUser() {
    const user = this.getCurrentUserInfo();
    if (user !== null) {
    } else {
      return null;
    }
  }

  isloggedInUser() {
    if (localStorage.getItem('User') !== null) {
      return true;
    } else {
      return false;
    }
  }

  isTempUser() {
    if (localStorage.getItem('TempUser') !== null) {
      return true;
    } else {
      return false;
    }
  }

  getCurrentLanguage() {
    return localStorage.getItem('locale');
  }


  SetUserList(userInfo: any) {
    localStorage.setItem('list', this.encrDecrService.encrypt(userInfo));
  }

  getUserList() {
    let userList: any;
    const info = localStorage.getItem('list');
    if (info != null) {
      userList = this.encrDecrService.decrypt(info);
    } else {
      return null;
    }
    return userList;
  }


  SetFilter(data: any) {
    localStorage.setItem('filterObj', this.encrDecrService.encrypt(data));
  }

  getFilter() {
    return this.encrDecrService.decrypt(localStorage.getItem('filterObj'));
  }

  SetDashboardFilter(data: any) {
    localStorage.setItem('dashfilter', this.encrDecrService.encrypt(data));
  }

  getDashboardFilter() {
    return this.encrDecrService.decrypt(localStorage.getItem('dashfilter'));
  }

  public setcurrencymapper(data: any) {
    sessionStorage.setItem(
      'setcurrencymappers',
      this.encrDecrService.encrypt(data)
    );
  }

  public getcurrencymapper() {
    return this.encrDecrService.decrypt(
      sessionStorage.getItem('setcurrencymappers')
    );
  }

  checkcurrencymapper() {
    if (sessionStorage.getItem('setcurrencymappers')) {
      return true;
    }
    return false;
  }

  setselectedcurrecyIsoCode(data: any) {
    localStorage.setItem(
      'setselectedcurrecy',
      this.encrDecrService.encrypt(data)
    );
  }

  getselectedcurrecyIsoCode() {
    return this.encrDecrService.decrypt(
      localStorage.getItem('setselectedcurrecy')
    );
  }

  checkselectedcurrecyIsoCode() {
    if (localStorage.getItem('setselectedcurrecy')) {
      return true;
    }
    return false;
  }
}
