import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { element } from "protractor";
import { IIndustry, IndustryModel } from "src/app/models/industry";
import { FeasibilityService } from "src/app/services/feasibility.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit, AfterViewInit {
  Industries: Array<IIndustry> = [];
  constructor(
    private feasibilityService: FeasibilityService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.feasibilityService
      .getIndustryList()
      .subscribe((data: IndustryModel) => {
        this.Industries = data.IndustryList;
      });
  }


  viewBtnClick(industryId: number) {
    const industry = this.Industries.find((m) => m.Id === industryId);

    let slug = industry.Name;

    if (slug) {
      const slug1 = slug.toLocaleLowerCase();
      slug = slug1.split(" ").join("-");
    }

    this.router.navigate(["/industry/", industryId, slug]);
  }

  incresewith(page: string) {
    let witdh = "450px";

    if (page === "plan") {
      witdh = "550px";
    }

    const divlist = document.getElementsByClassName("mat-menu-panel");
    for (let i = 0;   i < divlist.length; i++) {
      const ele = divlist[i] as HTMLElement;
      ele.style.overflow = "hidden";
      ele.style.maxWidth = witdh;
      if (page === "plan") {
        ele.style.maxHeight = "250px";
      }
    }
  }
}
