import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OpportunityService } from 'src/app/services/Opportunity.service';
// import {  Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { emailValidator } from '../../utils/app-validators';
import { environment } from 'src/environments/environment';
import {
  ResponseModel,
  HttpStatusCode,
} from 'src/app/shared/helper/Responsemodel/responsemodel';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { NewsltrModel } from 'src/app/models/feasibility';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // public lat: 40.678178;
  // public lng: -73.944158;
  // public zoom: 12;
  myForm: FormGroup;
  submitted = false;
  toppings = new FormControl();

  constructor(
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    private opportunityService: OpportunityService
  ) {}

  ngOnInit() {
    this.reactiveForm();
  }

  // subscribe() { }

  // reactiveForm() {
  //   this.myForm = this.fb.group({
  //     Fullname: ['', 	Validators.compose([Validators.required, Validators.minLength(3)]),	],
  //     Email: ['',  Validators.compose([Validators.required, emailValidator])],
  //   });
  // }
  reactiveForm() {
    this.myForm = this.fb.group({
      Fullname: [
        '',
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
      Email: ['', Validators.compose([Validators.required, emailValidator])],
    });
  }
  clearForm() {
    this.myForm.reset();
  }

  get f() {
    return this.myForm.controls;
  }

  validation() {
    if (
      this.myForm.controls.Fullname.value === '' ||
      this.myForm.controls.Email.value === ''
    ) {
      this.submitted = false;
    } else {
      this.submitted = true;
    }
  }
  submitForm() {
    // if (this.submitted) {
      this.submitted=true;
      const newsltr = {
        Id: 0,
        FullName: this.myForm.controls.Fullname.value,
        Email: this.myForm.controls.Email.value,
        // CreatedDate: 'August 11,2020'
      };
      if(this.myForm.valid){
      let url = '';
      url = `${environment.ApiUrl}SubscriberNewsLtr/AddSubscriber`;
      this.opportunityService
        .addNewsltr(url, newsltr)
        .subscribe((response: ResponseModel<any>) => {
          // tslint:disable-next-line: no-debugger
          if (response.status === HttpStatusCode.OK) {
            this.submitted = false;
            this.snackBar.open('Submit successfully!', '×', {
              panelClass: 'success',
              verticalPosition: 'top',
              duration: 3000,
            });
          } else {
            this.snackBar.open('Submitation Failed!', '×', {
              panelClass: 'success',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
          this.clearForm();
        });
    } 
  // }
    // else {
    //   this.snackBar.open('Please enter valid fullname or name!', '×', {
    //     panelClass: 'success',
    //     verticalPosition: 'top',
    //     duration: 3000,
    //   });
    // }
  }
  AllowAlphabet(evt) {
    evt = evt ? evt : window.Event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
      return true;
    } else {
      return false;
    }
  }
}
