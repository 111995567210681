import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetSetService } from 'src/app/shared/helper/service/get-set.service';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';
import { AuthService } from 'src/app/shared/helper/service/auth.service';
import { environment } from 'src/environments/environment';
import {
  HttpStatusCode,
  ResponseModel,
} from 'src/app/shared/helper/Responsemodel/responsemodel';
import { ConfirmedValidator } from 'src/app/theme/utils/app-validators';
import { IRegister } from 'src/app/models/plan.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { CartService } from 'src/app/services/cart.service';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { SharedService } from 'src/app/shared/helper/service/shared.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup;
  registerForm: FormGroup;
  changeForm: FormGroup;
  submitloginform = false;
  submitregisterform = false;
  OTP: any = ' ';
  hide = true;
  confirm = true;
  submitted = false;
  submitted1 = false;
  customValidationforTc = false;

  password: any;

  confirm_password: any;
  mobilePattern = '^((\\+91-?)|0)?[0-9]{10}$';

  ApiUrl: string = environment.ApiUrl;
  ShowOTPBox = false;

  showloginBox = true;
  ismobileview = false;

  @ViewChild('otpbox')
  OtpBox: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 990) {
      const element = document.getElementsByClassName(
        'mat-toolbar-row'
      )[0] as HTMLElement;
      this.ismobileview = true;
    } else {
      this.ismobileview = false;
    }
  }

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public snackBar: MatSnackBar,
    private getsetService: GetSetService,
    private behavioursubjectService: BehavioursubjectService,
    private authService: AuthService,
    public dialog: MatDialog,
    private location: Location,
    private cartService: CartService,
    private sharedService: SharedService,
    public dialogRef: MatDialogRef<SignInComponent>
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
          ),
        ]),
      ],

      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
    });
    this.reactiveForm();

    this.onResize();
  }
  reactiveForm() {
    this.registerForm = this.formBuilder.group(
      {
        firstname: ['', Validators.compose([Validators.required])],
        lastname: ['', Validators.compose([Validators.required])],
        username: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern(
              /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
            ),
          ]),
        ],
        agree: [false, Validators.requiredTrue],
        password: ['', Validators.required],
        otp: ['', Validators.pattern('[0-9]{4}')],
        confirmPassword: ['', Validators.required],
      },
      { validator: ConfirmedValidator('password', 'confirmPassword') }
    );
  }
  changepasswordForm() {
    this.changeForm = this.formBuilder.group(
      {
        firstname: ['', Validators.compose([Validators.required])],
        lastname: ['', Validators.compose([Validators.required])],
        username: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('[0-9]{10}'),
          ]),
        ],
        currentpassword: ['', Validators.required],
        agree: [false, Validators.requiredTrue],
        password: ['', Validators.required],
        otp: [''],
        confirmPassword: ['', Validators.required],
      },
      { validator: ConfirmedValidator('password', 'confirmPassword') }
    );
  }

  checkForm(form) {
    if (!form.terms.checked) {
      alert('Please indicate that you accept the Terms and Conditions');
      form.terms.focus();
      return false;
    }
    return true;
  }

  public onLoginFormSubmit(values: object): void {
    this.submitloginform = true;

    if (this.loginForm.valid) {
      const LoginObj = {
        Username: this.loginForm.value.email,
        Password: this.loginForm.value.password,
      };

      const url = `${environment.ApiUrl}User/login`;

      this.authService.Login(url, LoginObj).subscribe(
        (data) => {
          if (data.status === HttpStatusCode.OK) {
            this.submitloginform = false;
            this.snackBar.open(data.StatusMessage, '×', {
              panelClass: 'success',
              verticalPosition: 'top',
              duration: 3000,
            });
            this.close();

            this.getsetService.SetUser(data.data);
            this.behavioursubjectService.SendUserDataToHeader(data.data);
            this.behavioursubjectService.changeCartCount(true);
            this.sharedService.setLoginStatus(true);
            this.loginForm.reset();
            this.navigateToCheckout();

          } else if (data.status === HttpStatusCode.BAD_REQUEST) {
            this.submitloginform = false;
            this.snackBar.open(data.StatusMessage, '×', {
              panelClass: 'failure',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        },
        (error) => { }
      );
    }
  }
  showPassword() {
    this.hide = !this.hide;
  }
  showconfirmPassword() {
    this.confirm = !this.confirm;
  }
  get f() {
    return this.registerForm.controls;
  }
  get f1() {
    return this.changeForm.controls;
  }
  validation() {
    this.submitted = true;
  }
  validation1() {
    this.submitted1 = true;
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  public onRegisterFormSubmit(): void {

    if (this.registerForm.value.agree === false) {
      this.customValidationforTc = true;
    } else {
      this.customValidationforTc = false;
    }
    this.submitregisterform = true;
    if (this.registerForm.valid) {
      const registerInfo: IRegister = {
        FirstName: this.registerForm.value.firstname,
        LastName: this.registerForm.value.lastname,
        Otp: '',
        UserName: this.registerForm.value.username,
        Password: this.registerForm.value.password,
      };

      const url = this.ApiUrl + 'User/Registration'.trim();
      this.authService.register(url, registerInfo).subscribe(
        (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.submitregisterform = false;

            if (
              response.StatusMessage.includes('Otp Send')
            ) {
              this.ShowOTPBox = true;
              this.OtpBox.nativeElement.focus();
              this.snackBar.open(response.StatusMessage, '×', {
                panelClass: 'success',
                verticalPosition: 'top',
                duration: 3000,
              });
            } else {

              this.getsetService.SetUser(response.data);
              this.behavioursubjectService.SendUserDataToHeader(response.data);
              this.registerForm.reset();
              if (
                localStorage.getItem('isBulkCartOn') !== null &&
                localStorage.getItem('isBulkCartOn') === 'true'
              ) {
                this.navigateToCheckout();
              }

              this.close();

              this.snackBar.open(response.StatusMessage, '×', {
                panelClass: 'success',
                verticalPosition: 'top',
                duration: 3000,
              });
            }
          } else if (response.status === HttpStatusCode.BAD_REQUEST) {
            this.submitloginform = false;
            this.snackBar.open(response.StatusMessage, '×', {
              panelClass: 'error',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        },
        (error) => { }
      );
    }
  }

  AllowAlphabet(evt) {
    evt = evt ? evt : window.Event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
      return true;
    } else {
      return false;
    }
  }
  openDialog(): void {
    let dialogRef = this.dialog.open(ChangepasswordComponent, {});
    dialogRef.afterClosed().subscribe((result) => { });
  }


  public Verify(): void {
    this.submitregisterform = true;

    if (this.registerForm.valid) {
      const regObj = {
        FirstName: this.registerForm.value.firstname,
        LastName: this.registerForm.value.lastname,
        Otp: this.registerForm.value.otp,
        UserName: this.registerForm.value.username,
        Password: this.registerForm.value.password,
      };
      const url = this.ApiUrl + 'User/verifyLoginOtp';
      this.authService.Register(url, regObj).subscribe(
        (data) => {
          if (data.status === HttpStatusCode.OK) {
            this.submitregisterform = false;

            if (
              this.registerForm.value.otp === '' ||
              this.registerForm.value.otp == null
            ) {
              this.ShowOTPBox = true;
              this.snackBar.open(data.StatusMessage, '×', {
                panelClass: 'success',
                verticalPosition: 'top',
                duration: 3000,
              });
            } else {
              this.getsetService.SetUser(data.data);
              this.behavioursubjectService.SendUserDataToHeader(data.data);
              this.sharedService.setLoginStatus(true);
              this.registerForm.reset();

              this.navigateToCheckout();

              this.snackBar.open(data.StatusMessage, '×', {
                panelClass: 'success',
                verticalPosition: 'top',
                duration: 3000,
              });
            }
            this.close();
          } else if (data.status === HttpStatusCode.BAD_REQUEST) {
            this.submitloginform = false;
            this.snackBar.open(data.StatusMessage, '×', {
              panelClass: 'failure',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        },
        (error) => { }
      );
    }
  }
  isNumber(evt) {
    // tslint:disable-next-line: deprecation
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  close() {
    this.dialogRef.close();
    this.sharedService.IsLoginpopupOpen = false;
  }

  myFunction() {
    const x = document.getElementById('closebtn');
    if (x.style.display === 'none') {
      x.style.display = 'block';
    } else {
      x.style.display = 'none';
    }
  }

  goBack() {
    this.location.back();
  }

  navigateToCheckout() {

    // check Temp Cart is exist or Not
    const tempcartlist = this.cartService.getTempCartList();
    if (tempcartlist !== null) {
      if (tempcartlist.length > 0) {
        this.cartService
          .addBulkCart(tempcartlist)
          .subscribe((response: ResponseModel<any>) => {
            if (response.status === HttpStatusCode.OK) {
              localStorage.removeItem('cartlist');
              this.behavioursubjectService.changeCartCount(true);
              this.router.navigate(['/checkout']);

            } else {
              this.snackBar.open('Error while procced to checkout...:-(');
            }
          });
      }
    }
  }
}
