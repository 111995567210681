import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FAQSComponent implements OnInit {
  breadcrumb = [
    {
      name: 'FAQs',
      url: '/faqs',
    },
  ];


	public faqs = [];

  constructor(private behaviourSubjectService:BehavioursubjectService) { }


  ngOnInit(): void {
    this.behaviourSubjectService.changeBreadCrumbData(this.breadcrumb);
    this.getFaqs();
  }

	public getFaqs() {
		this.behaviourSubjectService.getFaqs().subscribe((data) => {
			this.faqs = data;
		});
	}
}
