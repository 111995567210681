import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category, Product } from './app.models';
import {
  ResponseModel,
  HttpStatusCode,
} from './shared/helper/Responsemodel/responsemodel';

export class Data {
  constructor(
    public categories: Category[],
    public compareList: Product[],
    public wishList: Product[],
    public cartList: Product[],
    public totalPrice: number,
    public totalCartCount: number
  ) { }
}

@Injectable()
export class AppService {
  public Data = new Data(
    [], // categories
    [], // compareList
    [], // wishList
    [], // cartList
    null, // totalPrice,
    0 // totalCartCount
  );

  public url = 'assets/data/';
  constructor(public http: HttpClient, public snackBar: MatSnackBar) { }

  // public getCategories(): Observable<Category[]> {
  //     return this.http.get<Category[]>(this.url + 'categories.json');
  // }

  public getProducts(type): Observable<Product[]> {
    return this.http.get<Product[]>(this.url + type + '-products.json');
  }

  public getProductById(id): Observable<Product> {
    return this.http.get<Product>(this.url + 'product-' + id + '.json');
  }

  public getBanners(): Observable<any[]> {
    return this.http.get<any[]>(this.url + 'banners.json');
  }

  public addToCart(product: Product) {
    let message;
    let status;

    this.Data.totalPrice = null;
    this.Data.totalCartCount = null;

    if (this.Data.cartList.filter((item) => item.id === product.id)[0]) {
      // tslint:disable-next-line:no-shadowed-variable
      const item = this.Data.cartList.filter((item) => {
        item.id = product.id;
      })[0];
      item.cartCount = product.cartCount;
    } else {
      this.Data.cartList.push(product);
    }
    // tslint:disable-next-line:no-shadowed-variable
    this.Data.cartList.forEach((product) => {
      this.Data.totalPrice =
        this.Data.totalPrice + product.cartCount * product.newPrice;
      this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
    });

    message = 'The product ' + product.name + ' has been added to cart.';
    status = 'success';
    this.snackBar.open(message, '×', {
      panelClass: [status],
      verticalPosition: 'top',
      duration: 3000,
    });
  }

  public resetProductCartCount(product: Product) {
    product.cartCount = 0;
    const compareProduct = this.Data.compareList.filter(
      (item) => item.id === product.id
    )[0];
    if (compareProduct) {
      compareProduct.cartCount = 0;
    }
    const wishProduct = this.Data.wishList.filter(
      (item) => item.id === product.id
    )[0];
    if (wishProduct) {
      wishProduct.cartCount = 0;
    }
  }

  // tslint:disable-next-line:member-ordering
  private userinfo = new BehaviorSubject(false);
  // tslint:disable-next-line:member-ordering
  getCurrentUserInfo = this.userinfo.asObservable();
  currentUserInfo(flag) {
    this.userinfo.next(flag);
  }


  getSliderConfig(numofSlidePerpage: number, ismobile: boolean) {

    var slideConfig = {
      "slidesToShow": !ismobile ? numofSlidePerpage : 1,
      "slidesToScroll": 1,
      "nextArrow": '<div class="nav-btn next-slide"><i class="fa fa-angle-right" ></i></div>',
      "prevArrow": '<div class="nav-btn prev-slide"><i class="fa fa-angle-left"></i></div>',
      "dots": false,
      "arrows": true,
      "infinite": false,
      'responsive': [
        {
          'breakpoint': 1024,
          'settings': {
            'slidesToShow': 4,
            'slidesToScroll': 1,
            'infinite': false,
            'dots': false,
          },
        },
        {
          'breakpoint': 600,
          'settings': {
            'slidesToShow': 3,
            'slidesToScroll': 1,
          },
        },
        {
          'breakpoint': 480,
          'settings': {
            'slidesToShow': 1,
            'slidesToScroll': 1,
          },
        },
      ]
    };

    return slideConfig;
  }
}
