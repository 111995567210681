import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IIndustry, IndustryModel } from '../models/industry';
import {
  ResponseModel,
  HttpStatusCode,
} from '../shared/helper/Responsemodel/responsemodel';
import { AppService } from '../app.service';
import { map } from 'rxjs/operators';
import { HttpClientExtended } from '../shared/helper/service/HttpClientExtended';
// tslint:disable-next-line:max-line-length
import {
  FeasibilityDescriptionModel,
  ReportType,
  IFeasibilityDescription,
  LeadAddModel,
  IFeasibilityPrice,
  AddToCartModel,
  FeasibilityReportsWithCount,
} from '../models/feasibility';

@Injectable({
  providedIn: 'root',
})
export class FeasibilityService {
  constructor(
    private http: HttpClientExtended,
    private appService: AppService
  ) {}

  getIndustryList(): Observable<IndustryModel> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<ResponseModel<IndustryModel>>(
        environment.ApiUrl + 'Industry/GetIndustryList?pageSize=0&pageNumber=0'
      )
      .pipe(this.http.extractResponseModel<IndustryModel>());
  }

  getReportTypes(): Observable<ReportType[]> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<ResponseModel<ReportType[]>>(
        environment.ApiUrl + 'Product/GetProductReportTypes'
      )
      .pipe(this.http.extractResponseModel<ReportType[]>());

    // tslint:disable-next-line: max-line-length
    // return this.http.get<ResponseModel<ReportType[]>>(environment.ApiUrl + 'Product/GetProductReportTypes').pipe(this.http.extractResponseModel<ReportType[]>());
  }

  getFeasibilityDetail(
    pageId: number
  ): Observable<FeasibilityDescriptionModel> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<ResponseModel<FeasibilityDescriptionModel>>(
        environment.ApiUrl +
          `PageDescription/GetPageDetailsByPageId?PageId=${pageId}`
      )
      .pipe(this.http.extractResponseModel<FeasibilityDescriptionModel>());
  }

  getFeasibilityDescriptionTab(
    reportid: number
  ): Observable<IFeasibilityDescription> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<ResponseModel<IFeasibilityDescription>>(
        environment.ApiUrl + `Product/GetReportById?Id=${reportid}`
      )
      .pipe(this.http.extractResponseModel<IFeasibilityDescription>());
  }
  // Latest Report
  getLatestReport(reportid: number): Observable<FeasibilityReportsWithCount> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<ResponseModel<FeasibilityReportsWithCount>>(
        environment.ApiUrl +
          `Product/GetLatestReportByReportTypeId?ReportTypeId=${reportid}`
      )
      .pipe(this.http.extractResponseModel<FeasibilityReportsWithCount>());
  }


  getAllReport(reportid: number): Observable<FeasibilityReportsWithCount> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<ResponseModel<FeasibilityReportsWithCount>>(
        environment.ApiUrl +
          `Product/GetReportListByReportTypeId?ReportTypeId=${reportid}`
      )
      .pipe(this.http.extractResponseModel<FeasibilityReportsWithCount>());
  }

  getindustrywiseReports(
    industryId: number,
    ProjectReportId: number
  ): Observable<FeasibilityReportsWithCount> {
    return this.http
      .get<ResponseModel<FeasibilityReportsWithCount>>(
        environment.ApiUrl +
          `Product/GetProductByIndustryId?IndustryId=${industryId}&ProjectReportId=${ProjectReportId}`
      )
      .pipe(this.http.extractResponseModel<FeasibilityReportsWithCount>());
  }

  getLatestFeasibilityReports(): Observable<IFeasibilityDescription[]> {
    return this.http
      .get<ResponseModel<IFeasibilityDescription[]>>(
        environment.ApiUrl + `Product/GetIndustryLatestReports`
      )
      .pipe(this.http.extractResponseModel<IFeasibilityDescription[]>());
  }

  addLead(lead: LeadAddModel): Observable<any> {
    // tslint:disable-next-line: no-debugger
    return this.http
      .post<ResponseModel<any>>(environment.ApiUrl + 'Lead/AddLead', lead)
      .pipe(this.http.extractResponseModel<any>());
  }

  getFeasibilityPricing(
    ReportTypeId: number,
    cityid: number
  ): Observable<IFeasibilityPrice> {
    if (cityid !== 0) {
      return this.http
        .get<ResponseModel<IFeasibilityPrice>>(
          environment.ApiUrl +
            `Price/GetPriceByReportTypeId?ReportTypeId=${ReportTypeId}&CityId=${cityid}`
        )
        .pipe(this.http.extractResponseModel<IFeasibilityPrice>());
    }
    return this.http
      .get<ResponseModel<IFeasibilityPrice>>(
        environment.ApiUrl +
          `Price/GetPriceByReportTypeId?ReportTypeId=${ReportTypeId}`
      )
      .pipe(this.http.extractResponseModel<IFeasibilityPrice>());
  }

  addToCart(cart: AddToCartModel): Observable<AddToCartModel> {
    return this.http
      .post<ResponseModel<AddToCartModel>>(
        environment.ApiUrl + `Cart/AddToCart`,
        cart
      )
      .pipe(this.http.extractResponseModel<AddToCartModel>());
  }
  getReportByTypeAndCity(
    ReportTypeId: number,
    cityid: number
  ): Observable<FeasibilityDescriptionModel> {
    // tslint:disable-next-line:max-line-length
    if (cityid !== 0) {
      // tslint:disable-next-line:max-line-length
      return this.http
        .get<ResponseModel<FeasibilityDescriptionModel>>(
          environment.ApiUrl +
            `/Product/GetReportByReportTypeCityId?ReportTypeId=${ReportTypeId}&CityId=${cityid}`
        )
        .pipe(this.http.extractResponseModel<FeasibilityDescriptionModel>());
    }
  }
}
