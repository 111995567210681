import { Injectable } from '@angular/core';
import {
CanActivate,
CanActivateChild,
CanLoad,
Route,
UrlSegment,
ActivatedRouteSnapshot,
RouterStateSnapshot,
UrlTree,
Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
providedIn: 'root'
})
export class AuthGuard implements CanActivate {
constructor(
private auth: AuthService,
private route: Router,
private router: Router
) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
if (this.auth.isLoggedIn()) {
// this.router.navigate(['/home/dashboard']);
this.router.navigate(['/']);
return true;
}
// this.router.navigate(['auth/registrationdashboard']);
// this.auth.logout();
return false;
}
}
