import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IURLDataModel, IURLModel } from 'src/app/models/industry';
import { CartService } from 'src/app/services/cart.service';
import { HttpStatusCode, ResponseModel } from 'src/app/shared/helper/Responsemodel/responsemodel';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';
import { SharedService } from 'src/app/shared/helper/service/shared.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {

  responseList: IURLDataModel[] = [];

  
  genblogList: IURLModel[] = [];
  planblogList: IURLModel[] = [];
  cityblogList: IURLModel[] = [];

  pageurlList: IURLModel[] = [];
  industryurlList: IURLModel[] = [];
  planurlList: IURLModel[] = [];

  oppertunityurlList: IURLModel[] = [];
  feasibityurlList: IURLModel[] = [];
  businessplanurlList: IURLModel[] = [];
  detailProjecturlList: IURLModel[] = [];

  
  showplan = true;
  showpage = true;
  showoppertunity = true;
  showindustries = true;
  showfeasiility = true;
  showbusinessplan = true;
  showdetailplan = true;
  showblog = true;

  breadcrumb = [
    {
      name: 'site map',
      url: '/sitemap',
    },
  ];
  constructor(
    private cartService: CartService,
    public router: Router,
    private behaviourSubjectService: BehavioursubjectService,
    private sharedService: SharedService,
  ) { }


  ngOnInit() {
    this.behaviourSubjectService.changeBreadCrumbData(this.breadcrumb);
    this.getReportSiteMap();
    this.getBlogSiteMap();
    this.setPageUrl();
    this.setPlanUrl();
    this.setIndustruesUrl();
  }

  public getReportSiteMap() {

    this.cartService.getReportList().subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        this.responseList = response.data;
        this.createUrlForReport(this.responseList);
      }
    });
  }


  public getBlogSiteMap() {

    this.cartService.getBlogUrlList().subscribe((response: ResponseModel<any>) => {
      if (response.status === HttpStatusCode.OK) {
        console.log(response);
        this.cityblogList = response.data.City;
        this.genblogList = response.data.General;
        this.planblogList = response.data.Plans;
      }
    });
  }


  createUrlForReport(responseList: IURLDataModel[]) {

    this.oppertunityurlList = [];
    this.feasibityurlList = [];
    this.businessplanurlList = [];
    this.detailProjecturlList = [];

    responseList.forEach(report => {

      // Oppertunity
      if (report.ReportTypeId == 8 && report.ReportId > 0) {
        const cityname = this.sharedService.createSlugtxt(report.CityName);
        this.oppertunityurlList.push(
          {
            name: report.Report,
            url: 'opportunityresearch/' + report.ReportId + '/' + cityname
          }
        );
      }

      // feasi
      if (report.ReportTypeId == 9 && report.ReportId > 0) {
        const reportname = this.sharedService.createSlugtxt(report.Report);
        const industryname = this.sharedService.createSlugtxt(report.Industry);
        this.feasibityurlList.push(
          {
            name: report.Report,
            url: 'reportdetail/' + report.ReportId + '/feasibilitycheck/' + industryname + '/' + reportname
          }
        );
      }

      // Business Plan
      if (report.ReportTypeId == 1 && report.ReportId > 0) {
        const reportname = this.sharedService.createSlugtxt(report.Report);
        const industryname = this.sharedService.createSlugtxt(report.Industry);
        this.businessplanurlList.push(
          {
            name: report.Report,
            url: 'reportdetail/' + report.ReportId + '/businessplanning/' + industryname + '/' + reportname
          }
        );
      }

      // Detailed
      if (report.ReportTypeId == 10 && report.ReportId > 0) {
     
        const reportname = this.sharedService.createSlugtxt(report.Report);
        const industryname = this.sharedService.createSlugtxt(report.Industry);
        this.detailProjecturlList.push(
          {
            name: report.Report,
            url: 'reportdetail/' + report.ReportId + '/detailproject/' + industryname + '/' + reportname
          }
        );
      }
    });


  }

  // GetAllBlogsUrl

  setPlanUrl() {
    this.planurlList = [
      { name: 'Opportunity Research', url: 'opportunityresearch' },
      { name: ' Project Report', url: 'detailproject' },
      { name: 'Business Plan', url: 'businessplanning' },
      { name: 'Feasibility Check', url: 'feasibilitycheck' },
      { name: 'Investment Pitch', url: 'investmentpitch' },
      { name: 'Visa Business Plans', url: 'visabusiness' },
      { name: 'Franchise Business Plans', url: 'franchisplan' },
      // { name: 'opportunity Research', url: 'nonprofit' },
      { name: 'Sales and Marketing', url: 'sales-marketing' },
      { name: 'Departmental Business Plans', url: 'Departmental-Business-plan' }
    ];
  }


  setIndustruesUrl() {

    this.industryurlList = [
      { name: 'Food Technology', url: 'industry/4/food-technology' },
      { name: 'Health Technology', url: 'industry/3/health-technology' },
      { name: 'Agriculture Technology', url: 'industry/5/agriculture-technology' },
      { name: 'Finance Technology', url: 'industry/2/finance-technology' },
      { name: 'Educational Technology', url: 'industry/9/educational-technology' },
      { name: 'Real Estate', url: 'industry/7/real-estate' },
      { name: 'Energy & Clean Technology', url: 'industry/6/energy--and-clean-technology' },
      { name: 'Automotive & Transport', url: 'industry/1/automotive-and-transport' },
      { name: 'Hospitality', url: 'industry/11/hospitality' },
      { name: 'Consumer Goods & Services', url: 'industry/8/consumer-goods-and-services' },
      { name: 'Fashion and Retails', url: 'industry/12/fashion-and-retails' },      
      { name: 'Entertainment', url: 'industry/10/entertainment-' },


    ];
  }


  setPageUrl() {
    this.pageurlList = [
      { name: 'About us', url: 'aboutus' },
      { name: 'Contact', url: 'contactus' },
      { name: 'FAQ', url: 'faqs' },
      { name: 'Careers', url: 'careers' },
      { name: 'Term & condition', url: 'termandcondition' },
      { name: 'Disclaimer', url: 'disclaimer' },
      { name: 'Privacy policy', url: 'privacypolicy' },
      { name: 'Sitemap', url: 'sitemap' },
    ];
  }
}
