import { Component, OnInit } from '@angular/core';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';

@Component({
  selector: 'app-termandcondition',
  templateUrl: './termandcondition.component.html',
  styleUrls: ['./termandcondition.component.scss']
})
export class TermandconditionComponent implements OnInit {

  breadcrumb = [
    {
      name: 'term & condition',
      url: '/termandcondition',
    },
  ];
  constructor(private behaviourSubjectService:BehavioursubjectService) { }

  ngOnInit(): void {
    this.behaviourSubjectService.changeBreadCrumbData(this.breadcrumb);

  }
}
