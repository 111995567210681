import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppService } from 'src/app/app.service';
import {
  HttpStatusCode,
  ResponseModel,
} from 'src/app/shared/helper/Responsemodel/responsemodel';
import { AuthService } from 'src/app/shared/helper/service/auth.service';
import { GetSetService } from 'src/app/shared/helper/service/get-set.service';
import { ConfirmedValidator } from 'src/app/theme/utils/app-validators';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
})
export class ChangepasswordComponent implements OnInit {
  isForgetFormShow = false;
  hide = true;
  confirm = true;
  submitted = false;
  ApiUrl: string = environment.ApiUrl;
  forgetUserName = '';
  otp = false;

  forgetPasswordForm: FormGroup;
  otpform: FormGroup;
  // tslint:disable-next-line: max-line-length
  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangepasswordComponent>,
    public getSetService: GetSetService,
    public appService: AppService,
    public snackBar: MatSnackBar,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.forgetPasswordForm = this.formBuilder.group(
      {
        otp: ['', [Validators.required, Validators.pattern('[0-9]{4}')]],
        password: ['', [Validators.required, , Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
      },

      { validator: ConfirmedValidator('password', 'confirmPassword') }
    );
    this.otpform = this.formBuilder.group({
      // otpinput: ['', Validators.required],
      otpinput: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
          ),
        ]),
      ],
    });
  }
  showPassword() {
    this.hide = !this.hide;
  }
  get f() {
    return this.forgetPasswordForm.controls;
  }
  showconfirmPassword() {
    this.confirm = !this.confirm;
  }
  validation() {
    this.submitted = true;
  }
  getOtpForforgetPassword() {
    if (this.otpform.valid) {
      const url = `${environment.ApiUrl}User/forgetPassword?username=${this.otpform.value.otpinput}`;
      this.authService
        .getOTPForLogin(url)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.otp = true;
            this.snackBar.open(
              `Verification code sent to your ${this.otpform.value.otpinput}`,
              '×',
              {
                panelClass: 'success',
                verticalPosition: 'top',
                duration: 3000,
              }
            );
          } else if (
            response.status === HttpStatusCode.BAD_REQUEST ||
            response.status === HttpStatusCode.INTERNAL_SERVER_ERROR
          ) {
            this.snackBar.open(`${response.StatusMessage}`, '×', {
              panelClass: 'error',
              verticalPosition: 'top',
              duration: 3000,
            });
          } else {
            this.snackBar.open(`${response.StatusMessage}`, '×', {
              panelClass: 'error',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        });
    }
  }
  setNewPassword() {
    if (this.forgetPasswordForm.valid) {
      const data = {
        UserName: this.otpform.value.otpinput,
        Otp: this.forgetPasswordForm.value.otp,
        NewPassword: this.forgetPasswordForm.value.password,
      };
      const url = `${environment.ApiUrl}User/setpassLogin`;

      this.authService
        .login(url, data)
        .subscribe((response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            this.forgetPasswordForm.reset();
            this.forgetUserName = '';
            this.isForgetFormShow = false;
            this.snackBar.open(`Your Password Reset Succesfully`, '×', {
              panelClass: 'success',
              verticalPosition: 'top',
              duration: 3000,
            });
            this.getSetService.SetUser(response.data);
            this.appService.currentUserInfo(true);
          } else if (
            response.status === HttpStatusCode.BAD_REQUEST ||
            response.status === HttpStatusCode.INTERNAL_SERVER_ERROR
          ) {
            this.snackBar.open(`${response.StatusMessage}`, '×', {
              panelClass: 'error',
              verticalPosition: 'top',
              duration: 3000,
            });
          } else {
            this.snackBar.open(`${response.StatusMessage}`, '×', {
              panelClass: 'error',
              verticalPosition: 'top',
              duration: 3000,
            });
          }
        });
    }
  }
  close() {
    this.dialogRef.close();
  }
  isNumber(evt) {
    evt = evt ? evt : window.Event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
