import { Injectable } from '@angular/core';
import { HttpClientExtended } from '../shared/helper/service/HttpClientExtended';
import { AppService } from '../app.service';
import { Observable } from 'rxjs';
import { CityModel } from '../models/city';
import { ResponseModel } from '../shared/helper/Responsemodel/responsemodel';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  constructor(
    private http: HttpClientExtended,
    private appService: AppService
  ) {}

  getCityList(): Observable<CityModel> {
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<ResponseModel<CityModel>>(
        environment.ApiUrl + 'api/City/GetCityList?pageSize=0&pageNumber=0'
      )
      .pipe(this.http.extractResponseModel<CityModel>());
  }

  addCity(cityName: string): Observable<any> {
    return this.http.post(
      `${environment.ApiUrl}City/AddCityFromUser?City=${cityName}`,
      {}
    );
  }
}
