import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BehavioursubjectService {
  private breadCrumbData = new BehaviorSubject([]);
  currentBreadCrumbData = this.breadCrumbData.asObservable();

  private tempUser = new BehaviorSubject(false);
  castTempUser = this.tempUser.asObservable();

  private verifyOtp = new BehaviorSubject(false);
  castverifyOtp = this.verifyOtp.asObservable();

  private cartCount = new BehaviorSubject(false);
  currentCartCount = this.cartCount.asObservable();

  constructor(private http:HttpClient) {}

  // Send data login to header
  private UserInfo = new BehaviorSubject('');
  LoginUserInfo = this.UserInfo.asObservable();

  // send data to header
  SendUserDataToHeader(data: any) {
    this.UserInfo.next(data);
  }

  changeCartCount(flag) {
    this.cartCount.next(flag);
  }

  changeBreadCrumbData(data: any) {
    this.breadCrumbData.next(data);
  }
  getTempUser(flag){
    this.tempUser.next(flag); 
  }
  getVerifyOtp(flag){
    this.verifyOtp.next(flag); 
  }

  public getFaqs(): Observable<any[]> {
		return this.http.get<any[]>('./assets/data/faqs.json');
	}

}
