import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ResponseModel } from '../shared/helper/Responsemodel/responsemodel';
import { AppService } from '../app.service';
import { map } from 'rxjs/operators';
import { HttpClientExtended } from '../shared/helper/service/HttpClientExtended';
import { IFeasibilityDescription, LeadAddModel, LeadAddModel3, AddToCartModel } from '../models/feasibility';
import { CityModel } from '../models/city';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService {

  constructor(private http: HttpClientExtended, private appService: AppService) { }

  getCityList(): Observable<CityModel> {
    return this.http.get<ResponseModel<CityModel>>(environment.ApiUrl + 'City/GetCityList?pageSize=0&pageNumber=0').pipe(this.http.extractResponseModel<CityModel>());
  }


  getFeasibilityDescriptionTab(industryId: number): Observable<IFeasibilityDescription> {
    return this.http.get<ResponseModel<IFeasibilityDescription>>(environment.ApiUrl + `Product/GetProductByIndustryId?IndustryId=${industryId}&ProjectReportId=9`).pipe(this.http.extractResponseModel<IFeasibilityDescription>());
  }
  addComment(url, comment): Observable<any> {
    return this.http.post(url, comment).pipe(map((response: Response) => response as any));
  }
  addLead(lead: LeadAddModel): Observable<any> {
    return this.http.post(environment.ApiUrl + 'Lead/AddLeads', lead).pipe(map((response: Response) => response as any));
  }
  addLeadd(url, leads: LeadAddModel3): Observable<any> {
    return this.http.post(url, leads).pipe(map((response: Response) => response as any));
  }
  addCart(url, cart: AddToCartModel): Observable<any> {
    return this.http.post(url, cart).pipe(map((response: Response) => response as any));
  }

  addMRORCart(url, cart): Observable<any> {
    return this.http.post(url, cart).pipe(map((response: Response) => response as any));
  }


  addNewsltr(url, newsltr): Observable<any> {
    return this.http.post(url, newsltr).pipe(map((response: Response) => response as any));
  }
  createTempUser(user): Observable<any> {
    let url = '';
    url = `${environment.ApiUrl}User/CreateTempUser`;
    return this.http.post(url, user).pipe(map((response: Response) => response as any));
  }
  verifyTempUser(user): Observable<any> {
    let url = '';
    url = `${environment.ApiUrl}User/VerifyTempUser`;
    return this.http.post(url, user).pipe(map((response: Response) => response as any));
  }
}

