import { Component, OnInit } from '@angular/core';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  breadcrumb = [
    {
      name: 'Careers',
      url: '/careers',
    },
  ];
  constructor(private behaviourSubjectService:BehavioursubjectService) { }

  ngOnInit(): void {
    this.behaviourSubjectService.changeBreadCrumbData(this.breadcrumb);

  }

}
