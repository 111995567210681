import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  ActivatedRouteSnapshot,
  UrlSegment,
  NavigationEnd,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Settings, AppSettings } from '../../../app.settings';
import { SidenavMenuService } from '../../../theme/components/sidenav-menu/sidenav-menu.service';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [SidenavMenuService],
})
export class BreadcrumbComponent implements AfterViewInit, OnInit {
  ismobileview = false;

  public pageTitle: string;
  public breadcrumbs: {
    name: string;
    url: string;
  }[] = [];

  public settings: Settings;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 990) {
      this.ismobileview = true;
    } else {
      this.ismobileview = false;
    }
  }

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public title: Title,
    public sidenavMenuService: SidenavMenuService,
    private behaviourSubjectService: BehavioursubjectService
  ) {
    this.settings = this.appSettings.settings;
  }
  ngOnInit(): void {
    this.behaviourSubjectService.currentBreadCrumbData.subscribe((data) => {
      this.breadcrumbs = data;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onResize();
    });
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }
}
