import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CityModel, ICity } from 'src/app/models/city';
import { CurrencyModel, ICurrency } from 'src/app/models/currency';
import { IIndustry } from 'src/app/models/industry';
import { OpportunityService } from 'src/app/services/Opportunity.service';
import { environment } from 'src/environments/environment';
import { ResponseModel } from '../Responsemodel/responsemodel';
import { GetSetService } from './get-set.service';
import { HttpClientExtended } from './HttpClientExtended';

@Injectable({
  providedIn: 'root',
})
export class SharedService implements OnInit {

  IsLoginpopupOpen = false;
  industryId = 0;
  Cities: Array<ICity> = [];
  pagename = 'feasibilitycheck';



  private currencySubject = new Subject<string>();
  private updateLoginSubject = new Subject<boolean>();


  constructor(
    private http: HttpClientExtended,
    private getSetService: GetSetService,
    public router: Router,
    public opportunityService: OpportunityService

  ) { }

  ngOnInit() {
    this.getCityList();
  }

  getSelectedCurrency() {
    return this.currencySubject.asObservable();
  }

  setSelectedCurrency(isoCode: string) {
    this.currencySubject.next(isoCode);
  }
  getLoginStatus() {
    return this.updateLoginSubject.asObservable();
  }

  setLoginStatus(status: boolean) {
    this.updateLoginSubject.next(status);
  }
  getcurrencyMapper(): Observable<CurrencyModel> {
    return this.http
      .get<ResponseModel<CurrencyModel>>(
        environment.ApiUrl + 'Currency/GetCurrencies'
      )
      .pipe(this.http.extractResponseModel<CurrencyModel>());
  }

  currencyConvertion(inrAmount: number, isoCode: string = 'no'): string {

    if (this.getSetService.checkselectedcurrecyIsoCode()) {
      const currencyList = this.getSetService.getcurrencymapper();

      if (isoCode === 'no') {
        isoCode = this.getSetService.getselectedcurrecyIsoCode();
      }

      const currency: ICurrency = currencyList.find((m) => m.IsoCode === isoCode);

      let symbol = '₹'

      if (isoCode === 'INR') {
        symbol = '₹';
      }
      if (isoCode === 'EUR') {
        symbol = '€';
      }
      if (isoCode === 'USD') {
        symbol = '$';
      }



      return symbol + ' ' + (inrAmount * currency.MultiplyFactor).toFixed(2);
    }
  }

  getCurrencyId(isoCode: string): number {
    let num = 1;
    if (isoCode === 'INR') {
      num = 1;
    }
    if (isoCode === 'EUR') {
      num = 3;
    }
    if (isoCode === 'USD') {
      num = 2;
    }

    return num;
  }

  currencySymbol(): string {
    const isoCode = this.getSetService.getselectedcurrecyIsoCode();

    if (isoCode === 'INR') {
      return '₹';
    }
    if (isoCode === 'EUR') {
      return '€';
    }
    if (isoCode === 'USD') {
      return '$';
    }
    //  console.log(isoCode);
    return '₹';
  }

  selectedISoCode(): string {
    return this.getSetService.getselectedcurrecyIsoCode();
  }

  getSliderConfig(slidesPerview: number) {
    const config = {
      slidesPerView: slidesPerview,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      speed: 500,
      effect: 'slide',
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        480: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 3,
        },
        960: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 5,
        },
      },
    };
    return config;
  }

  public createSlugtxt(name) {
    if(name){
      const slug2 = name.toLocaleLowerCase();
      return slug2.split(' ').join('-');
    }
  }

  getbreadcrumforindustry(
    pagename: string,
    industryname: string,
    reportname: string = ''
  ) {
    const industries = this.getSetService.getIndustirs();

    let industry: IIndustry;

    const slug1 = industryname.toLocaleLowerCase();
    industryname = slug1.split(' ').join('-');

    industries.forEach((ind) => {
      const slug2 = ind.Name.toLocaleLowerCase();
      const slug = slug2.split(' ').join('-');
      if (slug === industryname) {
        industry = ind;
      }
    });

    let breadcrum = [];

    if (pagename === 'Industry') {
      breadcrum = [
        {
          name: 'Industry',
          url: '/industry/' + industry.Id + '/' + industry.Name,
        },
        {
          name: industryname,
          url: '/industry/' + industry.Id + '/' + industry.Name,
        },
      ];
    }

    if (pagename === 'feasibilitycheck') {
      breadcrum = [
        {
          name: 'Feasibility Check',
          url: '/feasibilitycheck',
        },
        {
          name: industryname,
          url:
            '/industry/' + pagename + '/' + industry.Id + '/' + industry.Name,
        },
      ];
    }
    if (pagename === 'detailproject') {
      breadcrum = [
        {
          name: 'Project Detail',
          url: '/detailproject',
        },
        {
          name: industryname,
          url:
            '/industry/' + pagename + '/' + industry.Id + '/' + industry.Name,
        },
      ];
    }
    if (pagename === 'businessplanning') {
      breadcrum = [
        {
          name: 'Business Plan',
          url: '/businessplanning',
        },
        {
          name: industryname,
          url:
            '/industry/' + pagename + '/' + industry.Id + '/' + industry.Name,
        },
      ];
    }

    if (reportname) {
      breadcrum.push({
        name: reportname,
        url: '/',
      });
    }

    return breadcrum;
  }

  getCityList() {
    this.opportunityService.getCityList().subscribe((data: CityModel) => {
      this.Cities = data.CityList;
    });
  }

  public navigateToReport(report: any,cart:boolean) {
    if(cart){
      var ReportId;
      ReportId=report.ProductId; 
    }
    else{
      ReportId=report.Id;
    }

    if( report.Slug){
      report['Slug'] = this.createSlugtxt(report.Title);
    }

    this.getCityList();
    switch (report.ProductReportTypeId) {

      case 0:
        break;
      case 1:
        if (report.ProductReportTypeId === 0) {
          this.router.navigate(['/businessplanning']);
        } else {
          this.router.navigate([
            // '/feasibilitycheck/tableofcontent',
            // report.ProductReportTypeId,
            '/reportdetail/',
            ReportId,
            this.pagename,
            report.Title,
            report.Slug,
          ]);
        }
        break;
      case 2:
        this.router.navigate(['/investmentpitch']);
        break;
      case 3:
        this.router.navigate(['visabusiness']);
        break;
      case 4:
        this.router.navigate(['franchisplan']);
        break;
      case 5:
        this.router.navigate(['nonprofit']);
        break;
      case 6:
        this.router.navigate(['sales-marketing']);
        break;
      case 7:
        this.router.navigate(['Departmental-Business-plan']);
        break;
      case 8:
        this.Cities.forEach((city) => {
          if (city.Id === report.CityId) {
            this.router.navigate([
              '/opportunityresearch',
              report.CityId,
              city.Name.trim(),
            ]);
          }
        });
        break;
      case 9:
        if (report.ProductReportTypeId === 0) {
          this.router.navigate(['/feasibilitycheck']);
        } else {
          this.router.navigate([
            // '/feasibilitycheck/tableofcontent',
            // report.ProductReportTypeId,
            '/reportdetail/',
            ReportId,
            this.pagename,
            report.Title,
            report.Slug,
          ]);
        }
        break;
      case 10:
        if (report.ProductReportTypeId === 0) {
          this.router.navigate(['/detailproject']);
        }
        else {
          this.router.navigate([          
            '/reportdetail/',
            ReportId,
            this.pagename,
            report.Title,
            report.Slug,
          ]);
        }
        //  else {
        //   this.router.navigate([
        //     '/feasibilitycheck/tableofcontent',
        //     report.ProductReportTypeId,
        //   ]);
        // }
        break;
      default:
        break;
    }
  }

  getInvestmentRequiredList(value: number) {

    if (value === 1) {
      return [
        { "Id": 1, "Name": "Below ₹ 10 Lakh" },
        { "Id": 2, "Name": "Above ₹ 10 Lakh" },
        { "Id": 3, "Name": "Above ₹ 1 Crore" },
        { "Id": 4, "Name": "Above ₹ 10 Crore" },
        { "Id": 5, "Name": "Above ₹ 50 Crore" },
        { "Id": 6, "Name": "Not Sure" }
      ]
    }
    if (value === 2) {
      return [
        { "Id": 1, "Name": "Below $ 1 Million" },
        { "Id": 2, "Name": "Above $ 1 Million" },
        { "Id": 3, "Name": "Above $ 10 Million" },
        { "Id": 4, "Name": "Above $ 100 Million" },
        { "Id": 5, "Name": "Above $ 1 Billion" },
        { "Id": 6, "Name": "Not Sure" }
      ]
    }
    if (value === 3) {
      return [
        { "Id": 1, "Name": "Below € 1 Million" },
        { "Id": 2, "Name": "Above € 1 Million" },
        { "Id": 3, "Name": "Above € 10 Million" },
        { "Id": 4, "Name": "Above € 100 Million" },
        { "Id": 5, "Name": "Above € 1 Billion" },
        { "Id": 6, "Name": "Not Sure" }
      ]
    }
  }

  getInvestmentSalesList(value: number) {

    if (value === 1) {
      return [
        { "Id": 1, "Name": "Below ₹ 1 Lakh" },
        { "Id": 2, "Name": "Above ₹ 1 Lakh" },
        { "Id": 3, "Name": "Above ₹ 10 Lakh" },
        { "Id": 4, "Name": "Above ₹ 1 Crore" }
      ];
    }
    if (value === 2) {
      return [
        { "Id": 1, "Name": "Below $ 10 Thousand" },
        { "Id": 2, "Name": "Above $ 10 Thousand" },
        { "Id": 3, "Name": "Above $ 100 Thousand" },
        { "Id": 4, "Name": "Above $ 1 Million" }
      ];
    }
    if (value === 3) {
      return [
        { "Id": 1, "Name": "Below € 10 Thousand" },
        { "Id": 2, "Name": "Above € 10 Thousand" },
        { "Id": 3, "Name": "Above € 100 Thousand" },
        { "Id": 4, "Name": "Above € 1 Million" }
      ];
    }
  }

}
