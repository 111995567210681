import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() type: string;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count: number ;
  public align = 'center center';
  constructor(public appService: AppService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    if (this.product) {
      if (this.product.cartCount > 0) {
        this.count = this.product.cartCount;
      }
    }
    this.layoutAlign();
  }

  public layoutAlign() {
    if (this.type === 'all') {
      this.align = 'space-between center';
    } else if (this.type === 'wish') {
      this.align = 'start center';
    }  else {
      this.align = 'center center';
    }
  }





  public addToCart(product: Product) {
    // console.log(product)
    const currentProduct = this.appService.Data.cartList.filter(item => item.id === product.id)[0];
    if (currentProduct) {
      if ((currentProduct.cartCount + this.count) <= this.product.availibilityCount) {
        product.cartCount = currentProduct.cartCount + this.count;
      } else {
        this.snackBar.open('You can not add more items than available. In stock ' + this.product.availibilityCount + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        return false;
      }
    } else {
      product.cartCount = this.count;
    }
    this.appService.addToCart(product);
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }
}
