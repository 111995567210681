import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AddCartComponent } from './pages/feasibility/add-cart/add-cart.component';
import { AboutusComponent } from './pages/staticpages/aboutus/aboutus.component';
import { DisclaimerComponent } from './pages/staticpages/disclaimer/disclaimer.component';
import { TermandconditionComponent } from './pages/staticpages/termandcondition/termandcondition.component';
import { SitemapComponent } from './pages/staticpages/sitemap/sitemap.component';
import { FAQSComponent } from './pages/staticpages/faqs/faqs.component';
import { PrivacypolicyComponent } from './pages/staticpages/privacypolicy/privacypolicy.component';
import { CareersComponent } from './pages/staticpages/careers/careers.component';
import { ContactusComponent } from './pages/staticpages/contactus/contactus.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },

    {
        path: '', loadChildren: () =>
            import('./pages/home/home.module').then(m => m.HomeModule)
    },

    {
        path: 'account', loadChildren: () =>
            import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'Account Settings' }
    },

 
    {
        path: 'checkout', loadChildren: () =>
            import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' }
    },
    {
        path: 'contact', loadChildren: () =>
            import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' }
    },
    {
        path: 'sign-in', loadChildren: () =>
            import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' }
    },
    { path: 'blogs', loadChildren: () => import('./pages/blogs/blogs.module').then(m => m.BlogsModule), data: { breadcrumb: 'Blogs' } },
    {
        path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module')
            .then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' }
    },
    {
        path: 'contact', loadChildren: () => import('./pages/contact/contact.module')
            .then(m => m.ContactModule), data: { breadcrumb: 'Contact' }
    },
    {
        path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module')
            .then(m => m.SignInModule), data: { breadcrumb: 'Sign In ' }
    },

    {
        path: 'opportunityresearch', loadChildren: () => import('./pages/opportunity/opportunity.module')
            .then(m => m.OpportunityModule), data: { breadcrumb: 'Opportunity Research' }
    }, 
    {
        path: 'feasibilitycheck', loadChildren: () => import('./pages/feasibility/feasibility.module')
            .then(m => m.FeasibilityModule), data: { breadcrumb: 'Feasibility Check' }
    },


    {
        path: 'industry', loadChildren: () => import('./pages/feasibility/feasibility.module')
            .then(m => m.FeasibilityModule), data: { breadcrumb: 'Feasibility Check' }
    },

    {
        path: 'reportdetail', loadChildren: () => import('./pages/feasibility/feasibility.module')
            .then(m => m.FeasibilityModule), data: { breadcrumb: 'Feasibility Check' }
    },
   
    {
        path: 'investmentpitch', loadChildren: () => import('./pages/investment/investment.module')
            .then(m => m.InvestmentModule), data: { breadcrumb: 'investment pitch' }
    },

    {
        path: 'nonprofit', loadChildren: () => import('./pages/franchisplan/franchisplan.module')
            .then(m => m.FranchisplanModule), data: { breadcrumb: 'nonprofit bussiness plan' }
    },
    {
        path: 'Departmental-Business-plan', loadChildren: () => import('./pages/franchisplan/franchisplan.module')
            .then(m => m.FranchisplanModule), data: { breadcrumb: 'Departmental Business plan' }
    },
    {
        path: 'visabusiness', loadChildren: () => import('./pages/franchisplan/franchisplan.module')
            .then(m => m.FranchisplanModule), data: { breadcrumb: 'visabusiness bussiness plan' }
    },

    {
        path: 'sales-marketing', loadChildren: () => import('./pages/franchisplan/franchisplan.module')
            .then(m => m.FranchisplanModule), data: { breadcrumb: 'sales-marketing bussiness plan' }
    },
    {
        path: 'franchisplan', loadChildren: () => import('./pages/franchisplan/franchisplan.module')
            .then(m => m.FranchisplanModule), data: { breadcrumb: 'franchise bussiness plan' }
    },

    {
        path: 'cart', loadChildren: () => import('./pages/orders/Cart.module')
            .then(m => m.CartModule), data: { breadcrumb: 'Cart' }
    },
  
    {
        path: 'detailproject', loadChildren: () => import('./pages/feasibility/feasibility.module')
            .then(m => m.FeasibilityModule), data: { breadcrumb: 'Feasibility Check' }
    },
    {
        path: 'footer', loadChildren: () => import('./pages/staticpages/staticpages.module')
            .then(m => m.StaticpagesModule), data: { breadcrumb: '' }
    },
    {
        path: 'businessplanning', loadChildren: () => import('./pages/feasibility/feasibility.module')
            .then(m => m.FeasibilityModule), data: { breadcrumb: 'Feasibility Check' }
    },
    
    { path: 'aboutus', component: AboutusComponent, data: { breadcrumb: 'About Us' } },
    { path: 'contactus', component: ContactusComponent, data: { breadcrumb: 'Contact Us' } },

    { path: 'disclaimer', component: DisclaimerComponent, data: { breadcrumb: 'disclaimer' } },
    { path: 'termandcondition', component: TermandconditionComponent, data: { breadcrumb: 'term & condition' } },
    { path: 'sitemap', component: SitemapComponent, data: { breadcrumb: 'site map' } },
    { path: 'faqs', component: FAQSComponent, data: { breadcrumb: 'FAQs' } },
    { path: 'privacypolicy', component: PrivacypolicyComponent, data: { breadcrumb: 'Privacy Policy' } },
    { path: 'careers', component: CareersComponent, data: { breadcrumb: 'Careers' } },

    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
  // useHash: true
});
