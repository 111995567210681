import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ResponseModel, HttpStatusCode } from '../Responsemodel/responsemodel';
import { EncrDecrService } from './encr-decr.service';
import { IRegister } from 'src/app/models/plan.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Output() getLoggedIn: EventEmitter<any> = new EventEmitter();
  redirectUrl: string;
  constructor(
    private http: HttpClient,
    private encrDecrService: EncrDecrService,
    private router: Router
  ) {}
  // Login User
  Login(url: string, model: any): Observable<ResponseModel<any>> {
    const body = JSON.stringify(model);
    return this.http.post(url, body).pipe(
      map((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
          // this.toastrService.warning(response.statusMessage, '');
          return response;
        } else {
          return response;
        }
      })
    );
  }
  // Register User
  Register(url: string, model: any): Observable<ResponseModel<any>> {
    const body = JSON.stringify(model);
    return this.http.post(url, body).pipe(
      map((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
          return response;
        } else {
          return response;
        }
      })
    );
  }
  register(url, registerInfo: IRegister): Observable<any> {
    return this.http.post(url, registerInfo);
  }
  getOTPForLogin(url: string): Observable<any> {
    return this.http.get(url);
  }

  // ForgotPassword
  ForgotPassWord(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);
    return this.http.post(url, body).pipe(
      map((response: ResponseModel<any>) => {
        if (
          response.status === HttpStatusCode.OK ||
          HttpStatusCode.NO_CONTENT
        ) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
          // this.toastrService.warning(response.statusMessage, '');
        } else {
          return response;
        }
      })
    );
  }
  // Check user login status
  isLoggedIn() {
    if (localStorage.getItem('User') !== null) {
      const info = this.encrDecrService.decrypt(localStorage.getItem('User'));
      // const expTime = new Date(info.validTill);
      // const currentDate = new Date(Date.now());
      // if (expTime > currentDate) {
      return true;
      // }
    }
    localStorage.removeItem('User');
    // this.router.navigateByUrl(`/auth/registrationdashboard`);
    return false;
  }
 

  

  logOut() {
    
  	if (localStorage.getItem('User') != null) {
  		localStorage.removeItem('User');
  		localStorage.removeItem('TempUser');
  	}
  	
  }


  // External login User api
  ExternalLogin(url: string, model: any): Observable<any> {
    const body = JSON.stringify(model);
    return this.http.post(url, body).pipe(
      map((response: ResponseModel<any>) => {
        if (
          response.status === HttpStatusCode.OK ||
          HttpStatusCode.NO_CONTENT
        ) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
          // this.toastrService.warning(response.statusMessage, '');
        } else {
          return response;
        }
      })
    );
  }
  // Get Quize questions
  GetUserDetails(url: string) {
    return this.http.get(url).pipe(
      map((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
          // this.toastrService.warning(response.statusMessage, '');
          return response;
        } else {
          return response;
        }
      })
    );
  }
  // verify otp
  verifyOtp(url: string, odel: any): Observable<ResponseModel<any>> {
    const body = JSON.stringify(odel);
    return this.http.post(url, body).pipe(
      map((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          return response;
        } else if (response.status === HttpStatusCode.UNAUTHORIZED) {
          // this.toastrService.warning(response.statusMessage, '');
          return response;
        } else {
          return response;
        }
      })
    );
  }

  getCurrentUserInfo() {
    let currentUser: any;
    const user = localStorage.getItem('CurrentUser');
    if (user != null) {
      currentUser = this.encrDecrService.decrypt(user);
    } else {
      return null;
    }
    return currentUser;
  }
  GetLoginUserId() {
    let currentUser: any;
    const user = localStorage.getItem('CurrentUser');
    if (user != null) {
      currentUser = this.encrDecrService.decrypt(user);
    } else {
      return 0;
    }
    return currentUser.Id;
  }
  // logout user
  login(url, loginInfo: any): Observable<any> {
    return this.http.post(url, loginInfo);
  }
}
