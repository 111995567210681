import { SidenavMenu } from './sidenav-menu.model';

export const sidenavMenuItems = [
  new SidenavMenu(1, 'Home', '/', null, null, false, 0),
  new SidenavMenu(2, 'Opportunity Research', '/opportunityresearch', null, null, false, 0),
  new SidenavMenu(3, 'Feasibility Check', '/feasibilitycheck', null, null, false, 0),
  new SidenavMenu(4, 'Buisness Planning', '/businessplanning', null, null, false, 0),
  new SidenavMenu(5, 'Investment Pitch', '/investmentpitch', null, null, false, 0),



  new SidenavMenu(6, 'Plans', null, null, null, true, 0),

  new SidenavMenu(20, 'Pre planning', null, null, null, true, 6),
  new SidenavMenu(23, ' Opportunity Research', '/opportunityresearch', null, null, false, 20),
  new SidenavMenu(24, ' Feasibility Check', '/feasibilitycheck', null, null, false, 20),

  new SidenavMenu(21, 'planning', null, null, null, true, 6),
  new SidenavMenu(25, ' Business Plans', '/businessplanning', null, null, false, 21),
  new SidenavMenu(26, ' Detailed Project Report', '/detailproject', null, null, false, 21),
  new SidenavMenu(27, ' Visa Business Plans', '/visabusiness', null, null, false, 21),
  new SidenavMenu(28, '  Franchise Business Plans', '/franchisplan', null, null, false, 21),
  new SidenavMenu(29, '  Non Profit Business Plans', '/nonprofit', null, null, false, 21),


  new SidenavMenu(22, 'post planning', null, null, null, true, 6),
  new SidenavMenu(30, 'Sales and Marketing', '/sales-marketing', null, null, false, 22),
  new SidenavMenu(31, ' Departmental Business Plans', '/Departmental-Business-plan', null, null, false, 22),
  new SidenavMenu(32, ' Investment Pitch', '/investmentpitch', null, null, false, 22),

  new SidenavMenu(7, 'Industries', null, null, null, true, 0),
  new SidenavMenu(34, 'Food Tech', '/industry/4/food-technology', null, null, false, 7),
  new SidenavMenu(35, 'Health Tech', '/industry/3/health-technology', null, null, false, 7),
  new SidenavMenu(36, 'Agri Tech', '/industry/5/agriculture-technology', null, null, false, 7),
  new SidenavMenu(37, 'Fin Tech', '/industry/2/finance-technology', null, null, false, 7),
  new SidenavMenu(38, 'Edu Tech', '/industry/9/educational-technology', null, null, false, 7),
  new SidenavMenu(39, 'Real Estate', '/industry/7/real-estate', null, null, false, 7),
  new SidenavMenu(40, 'Energy & Clean Tech', '/industry/6/energy-&-clean-technology', null, null, false, 7),
  new SidenavMenu(41, 'Automotive & Transport', '/industry/1/automotive-&-transport', null, null, false, 7),
  new SidenavMenu(42, 'Hospitality', '/industry/11/hospitality', null, null, false, 7),
  new SidenavMenu(43, 'Consumer Goods & Service', '/industry/8/consumer-goods-and-services', null, null, false, 7),
  new SidenavMenu(44, 'Fashion & Retail', '/industry/12/fashion-and-retails', null, null, false, 7),
  new SidenavMenu(45, 'Entertainment', '/industry/10/entertainment-', null, null, false, 7),


  new SidenavMenu(8, 'Blogs', '/blogs', null, null, false, 0),

];
