import { Component, OnInit } from '@angular/core';
import { BehavioursubjectService } from 'src/app/shared/helper/service/behavioursubject.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  breadcrumb = [
    {
      name: 'About Us',
      url: '/aboutus',
    },
  ];
  constructor(private behaviourSubjectService:BehavioursubjectService) { }

  ngOnInit(): void {
    this.behaviourSubjectService.changeBreadCrumbData(this.breadcrumb);

  }
}
