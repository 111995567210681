import { HttpClient } from '@angular/common/http';
import { ResponseModel, HttpStatusCode } from '../Responsemodel/responsemodel';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HttpClientExtended extends HttpClient {

    extractResponseModel<T>() {

        return map((res: ResponseModel<T>) => {
            if (res.status === HttpStatusCode.OK) {
                return res.data;
            }
            if (res.status !== HttpStatusCode.OK) {
              //  alert(res.StatusMessage);
                // return res.data;
                return res.data as T;
            }
        });

    }
}
