import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Category } from './app.models';
import { AppService } from './app.service';
import { Settings, AppSettings } from './app.settings';
import { CityModel, ICity } from './models/city';
import { ICurrency } from './models/currency';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { CartService } from './services/cart.service';
import { OpportunityService } from './services/Opportunity.service';
import {
  HttpStatusCode,
  ResponseModel,
} from './shared/helper/Responsemodel/responsemodel';
import { AuthService } from './shared/helper/service/auth.service';
import { BehavioursubjectService } from './shared/helper/service/behavioursubject.service';
import { GetSetService } from './shared/helper/service/get-set.service';
import { SharedService } from './shared/helper/service/shared.service';
import { SidenavMenuService } from './theme/components/sidenav-menu/sidenav-menu.service';
import { ISearch } from './models/search.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SidenavMenuService],
})
export class AppComponent implements OnInit, AfterViewInit {
  loading: boolean;
  public settings: Settings;
  public showBackToTop = false;
  public currencyList: ICurrency[];
  public currency: ICurrency;
  public category: Category;
  public sidenavMenuItems: Array<any>;
  public Islogin = false;
  @ViewChild('sidenav', { static: true }) sidenav: any;
  public userInfo: any;
  cartListCnt = 0;
  searchList: any[];
  searchSelectname: any[];
  copySearchList:ISearch[];
  Cities: Array<ICity> = [];

  logoheight = '86px';

  ismobileview = false;
  pagename = 'feasibilitycheck';



  constructor(
    public appSettings: AppSettings,
    public sharedService: SharedService,
    public getset: GetSetService,
    public appService: AppService,
    public sidenavMenuService: SidenavMenuService,
    private opportunityService: OpportunityService,
    public router: Router,
    public authservice: AuthService,
    private behavioursubjectService: BehavioursubjectService,
    private getSetService: GetSetService,
    private cartService: CartService,
    public dialog: MatDialog
  ) {
    this.settings = this.appSettings.settings;
    this.behavioursubjectService.LoginUserInfo.subscribe((data) => {
      this.Islogin = this.authservice.isLoggedIn();
      this.userInfo = data;
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth < 990) {
      const element = document.getElementsByClassName(
        'mat-toolbar-row'
      )[0] as HTMLElement;
      this.logoheight = element.offsetHeight + 'px';
      this.ismobileview = true;
    } else {
      this.ismobileview = false;
      this.logoheight = '86px';
    }
  }

  ngOnInit() {

    if (!this.getset.checkcurrencymapper()) {
      this.sharedService.getcurrencyMapper().subscribe((data) => {
        const list = data.CurrencyCountryWiseList;
        this.getset.setcurrencymapper(list);
        this.currencyList = list;
        if (!this.getset.checkselectedcurrecyIsoCode()) {
          this.getset.setselectedcurrecyIsoCode(list[0].IsoCode);
          this.currency = list[0];        
        }else{
          const selectedIsocode =    this.getset.getselectedcurrecyIsoCode();
          this.currency = this.currencyList.find(m=>m.IsoCode === selectedIsocode);  
        }
      });
    } else {
      this.currencyList = this.getset.getcurrencymapper();

      if (!this.getset.checkselectedcurrecyIsoCode()) {
        this.getset.setselectedcurrecyIsoCode(this.currencyList[0].IsoCode);
        this.currency = this.currencyList[0];  
      } else{

        const selectedIsocode =    this.getset.getselectedcurrecyIsoCode();
        this.currency = this.currencyList.find(m=>m.IsoCode === selectedIsocode);

      }
    }


    this.getset.getIndustirs();
    this.Islogin = this.authservice.isLoggedIn();
    this.getSearchNames();

    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();

    this.behavioursubjectService.currentCartCount.subscribe((flag) => {
      if (this.getSetService.isloggedInUser()) {
        this.cartService
          .getCartList()
          .subscribe((response: ResponseModel<any>) => {
            if (response.status === HttpStatusCode.OK) {
              this.cartListCnt = response.data.length;
            } else {
              this.cartListCnt = 0;
            }
          });
      } else {
        const tempCartList = this.cartService.getTempCartList();
        if (tempCartList !== null) {
          this.cartListCnt = tempCartList.length;
        }
      }
    });

    this.userInfo = this.getSetService.getCurrentUserInfo();

    this.appService.getCurrentUserInfo.subscribe((flag) => {
      if (flag) {
        this.userInfo = this.getSetService.getCurrentUserInfo();
      }
    });
    // this.getCurrecyList();
    this.getCityList();

    let i = 0;

    setInterval(() => {
      i = i + 1000;
      if (i === (60*1000) || i === (72*1000) || i === (60 * 1000 * 2)) {
        if (!this.getSetService.isloggedInUser()) {
          this.openSignInDialog();
        }
      }
    }, 1000);
  }

  getCityList() {
    this.opportunityService.getCityList().subscribe((data: CityModel) => {
      this.Cities = data.CityList;
    });
  }

  public getCurrecyList() {



    const isoCode = this.getSetService.getselectedcurrecyIsoCode();
    this.currency = this.currencyList.find((m) => m.IsoCode === isoCode);

    this.getSetService.setselectedcurrecyIsoCode(this.currency.IsoCode);


    if (this.getSetService.checkcurrencymapper()) {

      this.currencyList = this.getSetService.getcurrencymapper();
      if (this.getSetService.checkselectedcurrecyIsoCode() && this.currencyList.length > 0) {
        const isoCode = this.getSetService.getselectedcurrecyIsoCode();
        this.currency = this.currencyList.find((m) => m.IsoCode === isoCode);
      } else {
        this.currency = {
          CountryId: 1,
          CountryName: 'India',
          Id: 20,
          IsoCode: 'INR',
          MultiplyFactor: 1,
          Symbol: '₹',
        };
        this.getSetService.setselectedcurrecyIsoCode(this.currency.IsoCode);
      }
    }
  }

  cuurencyChange(cur: ICurrency) {
    this.getSetService.setselectedcurrecyIsoCode(cur.IsoCode);
    this.currency = cur;
    // trigger
    this.sharedService.setSelectedCurrency(cur.IsoCode);
  }

  public remove(product) {
    const index: number = this.appService.Data.cartList.indexOf(product);
    if (index !== -1) {
      this.appService.Data.cartList.splice(index, 1);
      this.appService.Data.totalPrice =
        this.appService.Data.totalPrice - product.newPrice * product.cartCount;
      this.appService.Data.totalCartCount =
        this.appService.Data.totalCartCount - product.cartCount;
      this.appService.resetProductCartCount(product);
    }
  }

  public clear() {
    this.appService.Data.cartList.forEach((product) => {
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }
  public logOut() {
    this.cartListCnt = 0;
    this.authservice.logOut();
    this.behavioursubjectService.changeCartCount(true);
    this.behavioursubjectService.SendUserDataToHeader({
      Id: 1,
      Name: 'Mahesh Bhosale',
    });
  }
  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  // ****************************** get search report names **********************************
  public getSearchNames() {
    this.cartService
      .getReportList()
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.copySearchList = response.data;
        }
      });
  }

  // ********************** serch filter*********************************
  filterSearch(str: string, event) {
    if (str !== '' && str.trim() !== '') {
      this.searchSelectname = event.target.value;
      this.searchList = this.copySearchList.filter((name) => {
        return String(name.Report)
          .toLowerCase()
          .includes(str.toLowerCase().trim());
      });
      
      if (event.key === 'Enter') {
        this.router.navigateByUrl(
          `/products/search/name/list/${String(event.target.value).trim()}`
        );
        this.searchList = [];
      }
    } else {
      this.searchList = [];
    }
  }
  public createSlugtxt(name) {
    const slug2 = name.toLocaleLowerCase();
    return slug2.split(' ').join('-');
  }
  checkforEnter(event) { }

  public search(search: ISearch) {
    // if( name.Report){
    //   name['Report'] = this.createSlugtxt(name.Industry);
    // }
    switch (search.ReportTypeId) {
      case 0:
        break;
      case 1:
        if (search.ReportId === 0 ) {
          this.router.navigate(['/businessplanning']);
        } else {
          this.router.navigate([
            // '/feasibilitycheck/tableofcontent',
            // search.ReportId,
            '/reportdetail/',
            search.ReportId,
            this.pagename,
           this.createSlugtxt(search.Industry),
           this.createSlugtxt(search.Report),
          ]);
        }
        break;
      case 2:
        this.router.navigate(['/investmentpitch']);
        break;
      case 3:
        this.router.navigate(['visabusiness']);
        break;
      case 4:
        this.router.navigate(['franchisplan']);
        break;
      case 5:
        this.router.navigate(['nonprofit']);
        break;
      case 6:
        this.router.navigate(['sales-marketing']);
        break;
      case 7:
        this.router.navigate(['Departmental-Business-plan']);
        break;
      case 8:
        this.Cities.forEach((city) => {
          if (city.Id === search.CityId) {
            this.router.navigate([
              '/opportunityresearch',
              search.CityId,
              city.Name.trim(),
            ]);
          }
        });
        break;
      case 9:
        if (search.ReportId === 0) {
          this.router.navigate(['/feasibilitycheck']);
        } else {
          this.router.navigate([
          //   '/feasibilitycheck/tableofcontent',
          //   name.ReportId,
          // ]);
          '/reportdetail/',
          search.ReportId,
          this.pagename,
         this.createSlugtxt(search.Industry),
         this.createSlugtxt(search.Report),
        ]);
        }
        break;
      case 10:
        if (search.ReportId === 0) {
          this.router.navigate(['/detailproject']);
        } else {
          this.router.navigate([
            // '/feasibilitycheck/tableofcontent',
            // search.ReportId,
            '/reportdetail/',
            search.ReportId,
            this.pagename,
           this.createSlugtxt(search.Industry),
           this.createSlugtxt(search.Report),
          ]);
        }
        break;
      default:
        break;
    }
  }

  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    $event.target.documentElement.scrollTop > 300
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(
      this.sidenavMenuService.getSidenavMenuItems()
    );

    setTimeout(() => {
      this.onResize();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  public closeSubMenus() {
    if (window.innerWidth < 990) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  public openSidenav() {
    if (window.innerWidth < 990) {
      if (this.sidenav.opened) {
        this.sidenav.close();
      } else {
        this.sidenav.open();
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  openSignInDialog(): void {

    if (!this.sharedService.IsLoginpopupOpen) {
      this.sharedService.IsLoginpopupOpen = true;
      const dialogRef = this.dialog.open(SignInComponent);
      dialogRef.afterClosed().subscribe((result) => {
      });
    }
  }
}
